var site = site || {};
var generic = generic || {};
site.userTransObj = site.userTransObj || {};

site.userTransObj.trans_obj;

site.userTransObj.getTransObject = function() {

  if (typeof(this.trans_obj) !== 'undefined') {
    return site.userTransObj.trans_obj;
  }

  var valueKey = 'item_count';
  var itemsTotal = site.userInfoCookie.getValue(valueKey) || 0;
  // to reduce calls to trans.get, only call if items are in cart and we haven't been able to save data elsewhere
  if(itemsTotal > 0) {
    generic.jsonrpc.fetch({
    "method": "trans.get",
    "params": [{
        "trans_fields" : ["TRANS_ID", "totals"],
        "payment_fields" : [],
        "order_fields" : ["items", "samples", "offerCodes"]
    }],
    "onSuccess" : function (d) {

      var cartResponse = d.getValue();
      if ((typeof(cartResponse) === 'undefined') || (cartResponse == null))return;

      $(document).trigger('cart.loaded', cartResponse);

      // populate anything looking for basic cart data
      $('[data-pg-object="cart"] > [data-pg-prop], [data-pg-object="cart"][data-pg-prop]').each( function(i, node) {
        if(cartResponse.hasOwnProperty($(node).attr('data-pg-prop'))){
          $(node).html(cartResponse[$(node).attr('data-pg-prop')]);
        }
      });

      site.userTransObj.trans_obj = cartResponse;
      return site.userTransObj.trans_obj;

      // save in cookie...
      /* still too big to save to cookie...
      var simple_cart = {};
      simple_cart.formattedSubtotal = cartResponse.formattedSubtotal;
      simple_cart.trans = {"totals":{"TOTAL_COST_W_TAX":cartResponse.trans.totals.TOTAL_COST_W_TAX, "SHIPPING_W_TAX":cartResponse.trans.totals.SHIPPING_W_TAX}};
      simple_cart.items = [];
      for(var i = 0; i < cartResponse.order.items.length; i++){
        simple_cart.items.push({
          "prod.PRODUCT_ID":cartResponse.order.items[i]['prod.PRODUCT_ID'],
          "prod.PROD_RGN_NAME":cartResponse.order.items[i]['prod.PROD_RGN_NAME'],
          "prod.THUMBNAIL_IMAGE":cartResponse.order.items[i]['prod.THUMBNAIL_IMAGE'],
          "sku.SKU_ID":cartResponse.order.items[i]['sku.SKU_ID'],
          "sku.THUMBNAIL_IMAGE":cartResponse.order.items[i]['sku.THUMBNAIL_IMAGE'],
          "sku.HEX_VALUE":cartResponse.order.items[i]['sku.HEX_VALUE'],
          "sku.SHADENAME":cartResponse.order.items[i]['sku.SHADENAME'],
          "sku.PRODUCT_SIZE":cartResponse.order.items[i]['sku.PRODUCT_SIZE'],
          "sku.formattedPrice":cartResponse.order.items[i]['sku.formattedPrice']
        });
      }

      var simple_str = JSON.stringify(simple_cart);
      trans_cookie.setValue(simple_str);
      */
    },
      onFailure: function (d) {
        var cartResponse = d.getValue();

        if (typeof cartResponse === 'undefined' || cartResponse === null) {
          return;
        }
        $(document).trigger('cart.loaded', cartResponse);
        if ($('[data-pg-object="cart"] > [data-pg-prop], [data-pg-object="cart"][data-pg-prop]').length > 0) {
          $('[data-pg-object="cart"] > [data-pg-prop], [data-pg-object="cart"][data-pg-prop]').each(function (node) {
            var prop = $(node).attr('data-pg-prop');

            if (Object.prototype.hasOwnProperty.call(cartResponse, prop)) {
              $(node).html(cartResponse[prop]);
            }
          });
        }

        site.userTransObj.trans_obj = cartResponse;

        return site.userTransObj.trans_obj;
      }
  });
  }
};

site.userTransObj.setTransObject = function(obj) {
  site.userTransObj.trans_obj = obj;
};
